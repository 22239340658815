import { Button as MuiButton, ButtonProps as MuiButtonProps, Theme, withStyles } from '@material-ui/core';
import React from 'react';

interface ButtonProps extends MuiButtonProps {
  alignEndIcon?: boolean;
  alignStartIcon?: boolean;
}

const $Button: React.FC<ButtonProps> = ({
  color = 'primary',
  variant = 'contained',
  alignStartIcon,
  alignEndIcon,
  ...props
}) => <MuiButton color={color} variant={variant} {...props} />;

const styles = (theme: Theme) => ({
  disabled: {
    backgroundColor: (props: ButtonProps) =>
      `${
        props.color
          ? props.color === 'primary'
            ? theme.palette.primary.main
            : theme.palette.secondary.main
          : theme.palette.primary.main
      } !important`,
    opacity: 0.5
  },
  endIcon: (props: ButtonProps) => (props.alignEndIcon ? { position: 'absolute' as any, right: '1rem' } : {}),
  startIcon: (props: ButtonProps) => (props.alignStartIcon ? { left: '1rem', position: 'absolute' as any } : {})
});
export const Button = withStyles(styles)($Button);
