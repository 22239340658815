import ApolloClient from 'apollo-client';
import * as React from 'react';
import { PopupBannerModal } from 'src/app/components/generics/PopupBannerModal';

import {
  ACTIVE_MANUAL_POPUP_BANNER_QUERY,
  FetchActiveManualPopupBannerQueryResult,
  INCREASE_MANUAL_POPUP_BANNER_CLICK_COUNT,
  ManualPopupBanner
} from '../HomePage.queries';

interface HomePopupBannerProps {
  apolloClient: ApolloClient<any>;
}
interface HomePopupBannerState {
  popupBanner: ManualPopupBanner | null;
}

export class HomePopupBanner extends React.PureComponent<HomePopupBannerProps, HomePopupBannerState> {
  constructor(props: HomePopupBannerProps) {
    super(props);

    this.state = {
      popupBanner: null
    };
  }

  componentDidMount() {
    this.props.apolloClient
      .query({
        fetchPolicy: 'network-only',
        query: ACTIVE_MANUAL_POPUP_BANNER_QUERY
      })
      .then(result => result.data)
      .then((data: FetchActiveManualPopupBannerQueryResult) => {
        this.setState({
          popupBanner: data.activeManualPopupBanner
        });
      });
  }

  render() {
    const { popupBanner } = this.state;
    return (
      popupBanner && (
        <PopupBannerModal
          imageUrl={popupBanner.imageUrl}
          clickUrl={popupBanner.url}
          onBannerClick={popupBanner.url ? this.onBannerClick : undefined}
          onClose={this.onPopupClose}
        />
      )
    );
  }

  onBannerClick = () => {
    const { popupBanner } = this.state;
    if (popupBanner) {
      this.props.apolloClient
        .mutate({ mutation: INCREASE_MANUAL_POPUP_BANNER_CLICK_COUNT, variables: { id: popupBanner.id } })
        .then((result: any) => {
          if (result.errors) {
            throw new Error(result.errors[0].message);
          }
        })
        .catch(error => {
          throw new Error(error.message);
        });
    }
  };

  onPopupClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({
      popupBanner: null
    });
  };
}
