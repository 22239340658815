import { withStyles as withSassFile } from '@gdp/react-app/lib/helpers/withStyles';
import { Box, Paper, PaperProps, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as History from 'history';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from 'src/app/components/Button';
import { LOGIN } from 'src/app/constants/route';
import { CustomizationContextData } from 'src/app/Customization';

interface WelcomeSplashProps extends PaperProps {
  customization: CustomizationContextData;
  history: History.History;
}

const $WelcomeSplash: React.FC<WelcomeSplashProps> = ({ classes, className, customization, history, ...props }) => {
  React.useEffect(() => {
    const styleSheets: CSSStyleSheet[] = document.styleSheets as any;
    let counter = 0;
    const TOTAL_IMAGE = 2;
    for (const styleSheet of styleSheets) {
      try {
        const cssRules: CSSRule[] = styleSheet.cssRules as any;
        for (const cssRule of cssRules) {
          if (cssRule.cssText.includes('.no-js .bg-image, .no-webp .bg-image')) {
            (cssRule as any).style!.setProperty(
              'background-image',
              `url('${customization.assets.welcomeSplash.pngUrl}')`
            );
            counter++;
          } else if (cssRule.cssText.includes('.webp .bg-image')) {
            (cssRule as any).style!.setProperty(
              'background-image',
              `url('${customization.assets.welcomeSplash.webpUrl}')`
            );
            counter++;
          }
          if (counter === TOTAL_IMAGE) {
            break;
          }
        }
        // tslint:disable-next-line: no-empty
      } catch (error) {}
    }
  }, []);

  const onRegisterLoginClick = () => {
    history.push(LOGIN.path);
  };

  return (
    <Paper className={clsx(classes!.root, className, 'bg-image')} {...props} square={true}>
      <Box position="fixed" bottom="4rem" display="flex" flexDirection="column" justifyContent="center" width="100%">
        <Box display="flex" justifyContent="center">
          <Button size="large" fullWidth={false} onClick={onRegisterLoginClick}>
            <Box component="span" paddingLeft="3rem" paddingRight="3rem">
              <Typography variant="button">
                <b>
                  <FormattedMessage id="home.register.cta" />
                </b>
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

const styles = () => ({
  outlined: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  root: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%'
  }
});
export const WelcomeSplash = withSassFile(require('./WelcomeSplash.scss'))(withStyles(styles)($WelcomeSplash));
