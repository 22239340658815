import gql from 'graphql-tag';

export const HOME_PAGE_DATA_QUERY = gql`
  query HomeBackgrounds {
    homeAnnouncement {
      description
    }
    homeBackgrounds {
      id
      imageUrl
    }
    isWelcomeSplashAlreadyOpen @client
    myVouchersCount
    myProductVouchersCount
    ongoingOrdersCount
    ongoingMachineOrdersCount
  }
`;
export interface FetchHomePageDataQueryResult {
  homeAnnouncement: { description: string };
  homeBackgrounds: Array<{ id: number; imageUrl: string }>;
  isWelcomeSplashAlreadyOpen: boolean;
  myVouchersCount: number;
  myProductVouchersCount: number;
  ongoingOrdersCount: number;
  ongoingMachineOrdersCount: number;
}

export const ACTIVE_MANUAL_POPUP_BANNER_QUERY = gql`
  query ActiveManualPopupBanner {
    activeManualPopupBanner {
      id
      imageUrl
      url
    }
  }
`;
export interface ManualPopupBanner {
  id: number;
  name: string;
  imageUrl: string;
  url?: string;
  startDate: Date;
  endDate: Date;
  customerType: string;
  clickCount: number;
}
export interface FetchActiveManualPopupBannerQueryResult {
  activeManualPopupBanner: ManualPopupBanner;
}

export const INCREASE_MANUAL_POPUP_BANNER_CLICK_COUNT = gql`
  mutation IncreaseManualPopupBannerClickCount($id: ID!) {
    increaseManualPopupBannerClickCount(id: $id)
  }
`;
