import gql from 'graphql-tag';

export const FETCH_ME_QUERY = gql`
  query Me {
    me {
      birthdate
      company {
        id
        name
      }
      email
      gender
      id
      loyaltyPoint
      membershipEndPeriodAt
      name
      phoneNo
      referralCode
      status
    }
  }
`;

export interface FetchMeQueryResult {
  me: LoginQueryResult;
}

export interface CheckPhoneNoInput {
  phoneNo: string;
  token: string;
}

export interface ResendOtpInput extends CheckPhoneNoInput {}

export interface ResendOtpFormData extends CheckPhoneNoInput {}

export interface ChangePasswordFormData {
  password: string;
  phoneNo: string;
}

export interface OtpFormData {
  phoneNo?: string;
  otp?: string;
}

export interface LoginFormData {
  phoneNo: string;
  password: string;
}

export interface OtpCheckInput {
  otpInput: {
    phoneNo?: string;
    password?: string;
  };
}

export interface LoginInput {
  loginInput: {
    phoneNo: string;
    password: string;
  };
}

export interface ForgotPasswordInput {
  input: {
    phoneNo: string;
    password: string;
  };
}

export interface GenerateOTPForForgotPasswordInput {
  phoneNo: string;
  token: string;
}

export interface CustomerRegistrationForm {
  name: string;
  password: string;
  email?: string;
  phoneNo: string;
  status: number;
  gender?: string;
  birthdate?: string;
  referralCode?: string;
}

export interface CreateCustomerInput {
  input: {
    name: string;
    password: string;
    email?: string;
    phoneNo: string;
    status: number;
    gender?: string;
    birthdate?: string;
    referredBy?: string;
  };
}

export interface LoginCompany {
  name: string;
}
export interface LoginQueryResult {
  birthdate?: Date;
  company?: LoginCompany;
  email: string;
  gender?: string;
  id: string;
  loyaltyPoint: number;
  membershipEndPeriodAt: Date;
  name: string;
  phoneNo: string;
  referralCode: string;
  status: number;
}

export interface ChangePasswordQueryInput {
  input: {
    password: string;
    phoneNo: string;
  };
}

export interface CheckPhoneNoAndGenerateOtpIfNotExistQueryResult {
  checkPhoneNoAndGenerateOtpIfNotExist: number;
}

export interface GenerateOTPForForgotPasswordQueryResult {
  generateOtpForForgotPassword: number;
}

export interface RegenerateOtpQueryInput {
  phoneNo: string;
  token: string;
}

export interface RegenerateOtpQueryResult {
  regenerateOtp: number;
}

export interface DoLogoutResult {
  doLogout: boolean;
}

export const CHECK_PHONE_NO_AND_GENERATE_OTP_IF_NOT_EXIST_QUERY = gql`
  query CheckPhoneNoAndGenerateOtpIfNotExist($phoneNo: String!, $token: String) {
    checkPhoneNoAndGenerateOtpIfNotExist(phoneNo: $phoneNo, token: $token)
  }
`;

export const GENERATE_OTP_FOR_FORGOT_PASSWORD_QUERY = gql`
  query GenerateOTPForForgotPassword($phoneNo: String!, $token: String!) {
    generateOtpForForgotPassword(phoneNo: $phoneNo, token: $token)
  }
`;

export const REGENERATE_OTP_QUERY = gql`
  query regenerateOtp($phoneNo: String!, $token: String!) {
    regenerateOtp(phoneNo: $phoneNo, token: $token)
  }
`;

export const VALIDATE_OTP_QUERY = gql`
  query ValidateOtp($otpInput: LoginInput!) {
    validateOtp(otpInput: $otpInput)
  }
`;

export const DO_LOGIN_MUTATION = gql`
  mutation DoLogin($loginInput: LoginInput!) {
    doLogin(loginInput: $loginInput) {
      id
      name
      phoneNo
      email
      status
      referralCode
      gender
      birthdate
    }
  }
`;

export const DO_LOGOUT_MUTATION = gql`
  mutation DoLogout {
    doLogout
  }
`;

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      name
      phoneNo
      email
      status
      gender
      birthdate
    }
  }
`;

export const FORGET_PASSWORD_MUTATION = gql`
  mutation ForgetPassword($input: LoginInput!) {
    forgetPassword(input: $input) {
      id
      name
      phoneNo
      email
      status
      gender
      birthdate
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($input: UpdateCustomerPasswordInput!) {
    changePassword(input: $input) {
      id
      name
      phoneNo
      email
      status
      gender
      birthdate
    }
  }
`;
