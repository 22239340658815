import React from 'react';
import { Query } from 'react-apollo';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';

import { LOGIN } from '../../constants/route';
import { FETCH_ME_QUERY, FetchMeQueryResult, LoginQueryResult } from '../../containers/Login/Login.queries';

interface WithAuthProps {
  Component: React.ComponentType<any>;
  authenticationRequired: boolean;
  componentProps?: any;
}

type AuthRouteProps = WithAuthProps & RouteComponentProps<any>;

class AuthRoute extends React.Component<AuthRouteProps> {
  render() {
    return (
      <Query<FetchMeQueryResult> query={FETCH_ME_QUERY} fetchPolicy={'network-only'}>
        {({ loading, data }) => (loading ? null : this.handleRender(data))}
      </Query>
    );
  }

  private handleRender = (data?: FetchMeQueryResult) => {
    const { Component, authenticationRequired, location } = this.props;

    if (data && data.me) {
      return <Component {...this.props.componentProps} me={data.me}/>;
    } else {
      if (authenticationRequired) {
        return <Redirect to={{ pathname: LOGIN.path, state: { from: location } }} />;
      } else {
        return <Component {...this.props.componentProps} />;
      }
    }
  }
}

const ConnectedAuthRoute = withRouter(AuthRoute);

export const withAuth = (props: WithAuthProps) => <ConnectedAuthRoute {...props} />;

export interface AuthProps {
  me: LoginQueryResult;
}
