import { Badge, BadgeProps, withStyles } from '@material-ui/core';
import React from 'react';

type OngoingOrderBadgeProps = BadgeProps;

const $OngoingOrderBadge: React.FC<OngoingOrderBadgeProps> = ({ badgeContent, children, ...props }) => (
  <Badge {...props} badgeContent={badgeContent && badgeContent > 99 ? 99 : badgeContent}>
    {children}
  </Badge>
);

const styles = () => ({ anchorOriginTopRightRectangle: { transform: 'scale(1) translate(10%, -10%)' } });
export const OngoingOrderBadge = withStyles(styles)($OngoingOrderBadge);
