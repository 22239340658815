import { Box, Fab, Typography } from '@material-ui/core';
import * as History from 'history';
import React from 'react';
import { ApolloConsumer, Query } from 'react-apollo';
import Helmet from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { Button } from 'src/app/components/Button';
import { AnnouncementIcon, ChevronRightIcon, FilledBagIcon } from 'src/app/components/icons';
import { AuthProps, withAuth } from 'src/app/components/WithAuth';
import { ONGOING_ORDER, ORDER_DESTINATION } from 'src/app/constants/route';
import { HEADER_HEIGHT } from 'src/app/containers/Header/constants';
import { Header } from 'src/app/containers/Header/Header';
import { AssetCustomizationData, CustomizationContext, CustomizationContextData } from 'src/app/Customization';

import { HomeContainer } from './components/HomeContainer';
import { HomePopupBanner } from './components/HomePopupBanner';
import { OngoingOrderBadge } from './components/OngoingOrderBadge';
import { WelcomeSplash } from './components/WelcomeSplash';
import { FetchHomePageDataQueryResult, HOME_PAGE_DATA_QUERY } from './HomePage.queries';

type HomePageProps = AuthProps & RouteComponentProps;

const $HomePage: React.FC<HomePageProps> = ({ me, history }) => {
  const [selectedBgImageIndex, setBgImageIndex] = React.useState(0);

  let backgroundImageUrls: string[] = [];
  let intervalHandler: NodeJS.Timeout | undefined;

  React.useEffect(() => {
    intervalHandler = setInterval(() => {
      setBgImageIndex(selectedBgImageIndex < backgroundImageUrls.length - 1 ? selectedBgImageIndex + 1 : 0);
    }, 10000);

    return function cleanup() {
      if (intervalHandler) {
        clearInterval(intervalHandler);
      }
    };
  });

  return (
    <Query<FetchHomePageDataQueryResult> query={HOME_PAGE_DATA_QUERY}>
      {({ data, loading }) => {
        if (!data || loading) return null;
        const showWelcomeSplash = me === undefined;
        backgroundImageUrls = data.homeBackgrounds.map(homeBackground => homeBackground.imageUrl);

        const welcomeSplash = (customization: CustomizationContextData) => (
          <WelcomeSplash customization={customization} history={history} />
        );

        const header = (
          <Header
            bgcolor="transparent"
            customer={me}
            leftComponentType="sidebar"
            myVouchersCount={data.myVouchersCount + data.myProductVouchersCount}
            rightComponentTypes={['deal', 'notification']}
            rootProps={{ position: 'absolute', width: '100%' }}
          />
        );

        const content = (assets: AssetCustomizationData) => (
          <HomeContainer backgroundImageUrl={backgroundImageUrls[selectedBgImageIndex]}>
            <Announcement message={data.homeAnnouncement.description} />
            <MenuButton assets={assets} history={history} />
            <OngoingOrdersFab
              history={history}
              ongoingOrdersCount={data.ongoingOrdersCount + data.ongoingMachineOrdersCount}
            />
          </HomeContainer>
        );

        return (
          <>
            <FormattedMessage id="page.home">
              {titleMessage => (
                <Helmet>
                  <title>{titleMessage}</title>
                </Helmet>
              )}
            </FormattedMessage>
            <CustomizationContext.Consumer>
              {customization => (
                <>
                  {showWelcomeSplash && welcomeSplash(customization)}
                  <ApolloConsumer>
                    {apolloClient => (showWelcomeSplash ? null : <HomePopupBanner apolloClient={apolloClient} />)}
                  </ApolloConsumer>
                  {!showWelcomeSplash && header}
                  {!showWelcomeSplash && content(customization.assets)}
                </>
              )}
            </CustomizationContext.Consumer>
          </>
        );
      }}
    </Query>
  );
};

const Announcement: React.FC<{ message: string }> = ({ message }) =>
  message ? (
    <Box borderRadius="24px" bgcolor="rgba(255, 255, 255, 0.5)" padding="1rem" marginTop={`${HEADER_HEIGHT - 16}px`}>
      <Box display="flex" alignItems="center">
        <AnnouncementIcon />
        <Box component="span" paddingLeft="1rem">
          <Typography>{message}</Typography>
        </Box>
      </Box>
    </Box>
  ) : null;

const MenuButton: React.FC<{ assets: AssetCustomizationData; history: History.History }> = ({ assets, history }) => {
  const handleMenuClick = () => {
    history.push(ORDER_DESTINATION.path);
  };
  return (
    <Box
      position="absolute"
      bottom="0"
      width="calc(100% - 48px)"
      display="flex"
      justifyContent="center"
      paddingBottom="1.5rem"
      zIndex="1"
    >
      <Button
        size="large"
        color="secondary"
        fullWidth={true}
        startIcon={
          <Box display="flex" fontSize="38px !important">
            <img src={assets.menuIconUrl} alt="Menu Icon" />
          </Box>
        }
        endIcon={<ChevronRightIcon fontSize="small" />}
        onClick={handleMenuClick}
      >
        <Box component="span" width="100%" paddingTop="0.5rem" paddingBottom="0.5rem" paddingLeft="0.5rem">
          <Box display="flex" marginBottom="-5px">
            <Typography variant="button">
              <FormattedMessage id="home.order.cta">{label => (label as string).toUpperCase()}</FormattedMessage>
            </Typography>
          </Box>
          <Typography component="div" variant="caption" align="left">
            <FormattedMessage id="home.menu.cta" />
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};

const OngoingOrdersFab: React.FC<{ history: History.History; ongoingOrdersCount: number }> = ({
  history,
  ongoingOrdersCount
}) => {
  const handleFabClick = () => history.push(ONGOING_ORDER.path);
  if (ongoingOrdersCount === 0) return null;
  return (
    <Box position="absolute" bottom="0" right="0" paddingBottom="7rem" paddingRight="1.5rem">
      <OngoingOrderBadge badgeContent={ongoingOrdersCount} color="error">
        <Fab color="secondary" onClick={handleFabClick}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <FilledBagIcon />
            <Box fontSize="0.5rem" lineHeight={1} paddingTop="0.1rem">
              <Typography variant="inherit">
                <FormattedMessage id="home.ongoingOrder">
                  {label => (
                    <b>
                      {(label as string)
                        .toUpperCase()
                        .split(' ')
                        .join('\n')}
                    </b>
                  )}
                </FormattedMessage>
              </Typography>
            </Box>
          </Box>
        </Fab>
      </OngoingOrderBadge>
    </Box>
  );
};

export const HomePage = (props: HomePageProps) =>
  withAuth({
    Component: $HomePage,
    authenticationRequired: false,
    componentProps: props
  });
