import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';
import { BackCloseButton } from 'src/app/components/generics';

interface InjectedProps {
  imageUrl: string;
  clickUrl?: string;
  onBannerClick?: () => void;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

type PopupBannerModalProps = InjectedProps & RouteComponentProps;

@withStyles(require('./PopupBannerModal.scss'))
class PopupBannerModalComponent extends React.PureComponent<PopupBannerModalProps> {
  render() {
    const { imageUrl, onClose } = this.props;
    const overlayClass = classNames({
      PopupBannerModal__overlay: true
    });

    return (
      <div className={overlayClass} onClick={onClose}>
        <div className="PopupBannerModal__container">
          <div
            className="PopupBannerModal__content"
            style={{
              backgroundImage: `url("${imageUrl}")`
            }}
            onClick={this.onBannerClick}
          >
            <div className="PopupBannerModal__close">
              <BackCloseButton backState={false} format="small-rounded" onClick={onClose} color="blackInverse" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  onBannerClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { clickUrl, onBannerClick } = this.props;
    event.stopPropagation();
    if (onBannerClick) {
      onBannerClick();
    }
    if (clickUrl) {
      window.open(clickUrl, '_blank');
    }
  };
}

export const PopupBannerModal = withRouter(PopupBannerModalComponent);
