import { Container, ContainerProps, withStyles } from '@material-ui/core';
import React from 'react';

interface HomeContainerProps extends ContainerProps {
  backgroundImageUrl: string;
}

const $HomeContainer: React.FC<HomeContainerProps> = ({ children, backgroundImageUrl, ...props }) => (
  <Container {...props}>{children}</Container>
);

const styles = () => ({
  root: {
    backgroundImage: (props: HomeContainerProps) => `url('${props.backgroundImageUrl}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
    paddingTop: '1rem',
    transition: 'background-image 1s ease-in-out'
  }
});
export const HomeContainer = withStyles(styles)($HomeContainer);
